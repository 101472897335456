.carfilter {
  background: $secondary;
  padding: 2.3rem 0 1rem 0;
  color: #FFF;

  .filter-title {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2rem;
    margin-bottom: 1.4rem;
  }

  label {
    font-size: 1.7rem;
    position: relative;
    padding-left: 2.2rem;
    margin-right: 2.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.3rem;

    &:hover {
      cursor: pointer;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.1rem solid $primary;
    background-color: $secondary;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0.9rem;
      height: 0.9rem;
      background: $primary;
      top: 0.2rem;
      left: 0.2rem;
      opacity: 0;
    }

  }

  input[type="checkbox"]:checked ~ .checkmark:after {
    opacity: 1;
  }

  input[type=checkbox]:disabled + .checkmark {
    opacity: .4;
  }

  .custom-select {
    width: 100%;
  }

  .selectric-wrapper {
    margin-bottom: 1.3rem;
  }

  .selectric {
    background: $secondary;
    color: $text-light;
    border: 1px solid $primary;
  }

  .selectric .button {
    height: 3rem;
    line-height: 3rem;
    color: $text-light;
    background: transparent;

    &:after {
      content: url("../img/pfeil-fahrzeugsuche.svg");
      border: none;
      width: 30px;
      height: 30px;
      padding-top: 0.7rem;
    }
  }

  .selectric .label {
    height: 3rem;
    line-height: 3rem;
    color: $text-light;
    font-size: 1.7rem;
  }

  .selectric-items li {
    padding: 0.5rem 1rem;
    color: $text-dark;
    font-size: 1.7rem;
  }

  .filter-submit {
    height: 3.2rem;
    background: $primary;
    border: none;
    box-shadow: none;
    width: 100%;
    color: #FFF;
    font-size: 1.6rem;
    text-align: left;
    padding-left: 1.1rem;

    span {
      padding-right: 0.9rem;
    }
  }

}