@include media-breakpoint-down(sm) {

  .nav-outer {
    z-index: 1000;
  }

  .nav-container {
    padding: 0;
  }

  .navbar-toggle {
    position: relative;
    top: 3.7rem;
    background-color: $primary;
    margin: 0 0 0 -15px;
    border: none;
    border-radius: 0;
    width: 55px;
    height: 33px;
    padding: 0 13px;
    float: right;
    .icon-bar {
      background-color: #fff;
      height: 3px;
      border-radius: 1px;
      display: block;
    }
    .icon-bar + .icon-bar {
      margin-top: 4px;
    }
    /*&:hover, &:focus {
      background-color: $brand-grey;
    }*/
  }

  .navbar-toggle[aria-expanded="true"] {
    .icon-bar--one {
      transform: rotate(45deg) translate(6px, 4px);
      width: 2.5rem;
    }
    .icon-bar--two {
      opacity: 0;
    }
    .icon-bar--three {
      transform: rotate(-45deg) translate(7px, -5px);
      width: 2.5rem;
    }
  }

  .navbar-toggle[aria-expanded="true"] .icon-bar {
    transition: .20s;
  }

  #main-nav {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    top: 8rem;
    border-color: transparent;
    margin-right: 0;
    padding-right: 0;
    border-top: none;
    z-index: 9999;
    box-shadow: 0 1rem 1rem 0 rgba(60,60,59,.25);
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  #main-nav ul {
    list-style-type: none;
    margin-bottom: 0;
  }

  #main-nav ul {
    background: $primary;
    position: relative;
    float: none;
    overflow: hidden;
    font-size: 12px;
    padding: 20px!important;
  }

  #main-nav ul li {
    position: relative;
    border-top: 1px solid $primary;
  }

  #main-nav ul li a {
    color: #fff;
    text-transform: uppercase;
    &:hover, &:focus, &:active {
      color: $text-dark!important;
    }
  }

  .dropdown-toggle {
    border-bottom: none!important;
  }

  #main-nav > ul > li:first-of-type {
    border: none;
  }

  #main-nav ul li ul {
    padding: 0;
  }

  #main-nav > ul > li {
    padding: 0;
  }

  #main-nav > ul > li a {
    padding: 8px 60px 8px 0;
    font-size: 2rem;
    line-height: 2rem;
    display: block;
    text-transform: uppercase;
  }

  #main-nav ul.level1 {
    padding: 0;
  }

  #main-nav ul.level2 {
    //background-color: #ebeced;
    display: none;
    padding: 0!important;
    border-left: 0.5rem solid rgba(255,255,255,0.2);
  }

  #main-nav ul.level3 {
    background-color: #e2e3e4;
    display: none;
  }

  #main-nav ul.level4 {
    background-color: #d7d8d9;
    display: none;
  }

  #main-nav ul.level2 a {
    padding-left: 10px;
    font-size: 1.7rem;
  }

  #main-nav ul.level3 a {
    padding-left: 30px;
  }

  #main-nav ul.level4 a {
    padding-left: 40px;
  }

  .icon-unfold:before {
    font-family: 'FontAwesome';
    content: "\f105";
    color: #fff;
    font-size: 2.8rem;
    position: absolute;
    right: -2rem;
    top: 0;
    padding: 0.3rem 0.9rem;
    width: 5.5rem;
    height: 3.6rem;
    text-align: center;
    //background-color: $primary;
    background-color: rgba(255,255,255,0.2);
    //border-bottom: 1px solid #fff;
    cursor: pointer;
    z-index: 1000;
    line-height: 2.8rem;
  }

  .icon-unfold--down:before {
    content: "\f107";
  }

  .menu-wrapper {
    position: static;
  }

  @media (min-width: 375px) {

    .navbar-toggle {
      height: 4.4rem;
      top: 1rem;
    }

    .navbar-toggle[aria-expanded="true"] {
      .icon-bar--one {
        transform: rotate(45deg) translate(6px, 4px);
      }
      .icon-bar--three {
        transform: rotate(-45deg) translate(6px, -4px);
      }
    }

    #main-nav {
      top: 10.8rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .header-main .row {
      position: relative;
    }
    #main-nav {
      width: 30rem;
      left: auto;
    }
  }
}





@include media-breakpoint-up(md) {

  .nav-outer {
    margin: 0;
    background: none;
    width: 100%;
  }

  .menu-wrapper {
    display: flex;
  }

  #main-nav {
    display: block;
    position: relative;
    top: 0.6rem;
    z-index: 999;
  }

  .navbar-toggle {
    display: none;
  }

  #main-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #main-nav > ul {
    display: flex;
    justify-content: space-between;
  }

  #main-nav .level1 > li {
    position: relative;
    display: inline-block;
    padding: 0;
  }

  #main-nav .level1 > li:hover {
    //background-color: #fff;
    > a {
      //color: $primary;
    }
  }

  #main-nav .level1 > li > a {
    display: inline-block;
    padding: 0;
    color: $text-dark;
    font-size: 1.5rem;
    text-transform: uppercase;

    &:hover {
      color: $primary;
    }
  }

  #main-nav .level2 {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 3.8rem;
    left: -2rem;
    background: #FFF;
    border-top: 0.5rem solid $primary;
    padding: 2rem 1.5rem 1rem 2rem;
    font-size: 1.5rem;
    box-shadow: 0 1rem 1rem 0 rgba($secondary,0.25);
    transition: all 0.3s 0.2s;
  }

  #main-nav .level2 > li {
    margin-bottom: 0.8rem;
  }

  #main-nav .level2 > li > a {
    color: $text-dark;
    white-space: nowrap;

    &:hover {
      color: $primary;
    }
  }
  #main-nav li.dropdown:hover .level2,
  #main-nav li.dropdown:focus .level2,
  #main-nav li.dropdown:active .level2 {
    visibility: visible;
    opacity: 1;
  }

/*
  #main-nav .level2 {
    font-size: 16px;
    text-align: left;
    position: absolute;
    left: 15px;
    top: 100%;
    padding: 20px 1000rem;
    margin: 0 -1000rem;
    z-index: 101;
    visibility: hidden;
    background: rgba(255, 255, 255, .8);
    opacity: 0;
    transition: opacity 1s ease;
  }

  #main-nav .level2 > li {
    padding: 8px 0;
    line-height: 15px;
  }

  #main-nav .level2 > li > a {
    text-align: left;
    white-space: nowrap;
    color: #000;
    &:hover {
      color: $primary;
    }
  }

  #main-nav .dropdown:hover {
    padding-bottom: 8px;
    > .level2 {
      visibility: visible!important;
      opacity: 1;
    }
  }

  #main-nav .level3 {
    display: none;
  }
*/
  // http://jsfiddle.net/doubleswirve/xbLrW/2/

}

@include media-breakpoint-up(lg) {



}