footer {
  background-color: $secondary;
  color: $text-light;
  background-image: url("../img/balken-bg.svg");
  background-position: -5rem bottom;
  background-repeat: no-repeat;
}

footer a {
  color: $text-light;
}

.footer-main {
  position: relative;
  padding-top: 3.5rem;
  overflow: hidden;

}

.footer__logo {
  margin-bottom: 3rem;
}

.footer__contact {
  margin-bottom: 4rem;
}

.footer__contact p {
  line-height: 2.4rem;
}

.footer__social {
  margin-bottom: 5rem;
}

.footer__social-listitem a {
  display: block;
  width: 4rem;
  height: 4rem;
  background: $tertiary;
  text-align: center;
  font-size: 2.8rem;
  line-height: 4rem;
  color: $text-dark;

  &:hover {
    color: $text-light;
  }
}

.footer__social-listitem:not(:last-of-type) a {
  margin-right: 1.5rem;
}


.footer-nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-nav__list.level-1 > li {
  margin-bottom: 1rem;
}

.footer-nav__list.level-1 a {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}

.footer-nav__list .level-2 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-nav__list .level-2 a {
  font-size: 1.5rem;
  text-transform: none;
  font-weight: 400;
}

.footer-metanav__listitem {
  margin-right: 1rem;
  padding: 0;
}

.footer-metanav__listitem:not(:last-of-type):after {
  content: "//";
  margin-left: 10px;
}

.footer-metanav__listitem a {
  color: #fff;

  &:hover {
    color: $primary;
  }
}

.footer-meta {
  background: $tertiary;
  height: 4.9rem;
}

.footer-meta .container {
  height: 100%;
}

.footer-meta .container nav {
  height: 100%;
  display: flex;
  align-items: center;
}

.footer__map {
  margin: 0 -1.5rem;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(sm) {

  .footer__map {
    margin: 0;
  }

  .footer-metanav__listitem {
    margin-right: 0;
    margin-left: 1rem;
  }

  .footer-metanav__listitem a {
    font-size: 1.4rem;
  }

  .footer-metanav__listitem:not(:last-of-type):after {
    font-size: 1.4rem;
  }

}

@include media-breakpoint-up(md) {

  .footer-metanav__listitem a {
    font-size: 1.5rem;
  }

  .footer-metanav__listitem:not(:last-of-type):after {
    font-size: 1.5rem;
  }

}

@include media-breakpoint-up(lg) {

  .footer__map img {
    max-width: none!important;
  }

  .footer__map-xl {
    position: absolute;
    top: 0;
    left: 56.5%;
    width: 43.5%;
  }

  .footer__contact {
    margin-bottom: 5.5rem;
  }

  .footer-metanav__listitem a {
    font-size: 1.7rem;
  }

  .footer-metanav__listitem:not(:last-of-type):after {
    font-size: 1.7rem;
  }

  .footer-nav__list .level-2 a {
    font-size: 1.7rem;
  }

}