.formcomponent {
  max-width: 80rem;
  margin: 5rem auto;

  .form-control {
    font-size: 1.3rem;
  }

  input, select {
    height: 3.2rem;
  }

  input[type="date"] {
    padding: .1rem .75rem
  }

  input[type="radio"] {
    height: auto;
  }

  input[type="radio"] + label {
    margin-left: 1rem;
  }

  input[type="checkbox"] {
    height: auto;
  }

  input[type="checkbox"] + label {
    margin-left: 1rem;
  }

  .form-check p {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .row>.col, .row>[class*=col-] {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }


  @include media-breakpoint-up(md) {

    input, select {
      height: 4rem;
    }

    input[type="radio"] {
      height: auto;
    }

    label {
      font-size: 1.3rem;
    }

    p {
      font-size: 1.3rem;
    }

    button[type="submit"] {
      max-width: 25rem;
      float: right;
      margin-top: 1rem;
    }

  }

}