.wysiwyg-content ul,
#wysiwyg-editor ul,
#tinymce ul {
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
}

.wysiwyg-content p,
#wysiwyg-editor p,
#tinymce p {
  font-size: 1.5rem;
  line-height: 1.4;
}

.wysiwyg-content ul li,
#wysiwyg-editor ul li,
#tinymce ul li {
  position: relative;
  padding: 0 0 0 16px;
  font-size: 1.5rem;
  line-height: 1.4;
  hyphens: auto;
}

.wysiwyg-content ol,
#wysiwyg-editor ol,
#tinymce ol {
  padding: 0 0 0 16px;
}

.wysiwyg-content ol li,
#wysiwyg-editor ol li,
#tinymce ol li {
  font-size: 1.5rem;
  line-height: 1.4;
  hyphens: auto;
  position: relative;
  background-image: none;
}

.wysiwyg-content a,
#wysiwyg-editor a,
#tinymce a {
  color: $primary;
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.wysiwyg-content ul li {
  list-style-position: outside;
  margin-left: 22px;
  padding-left: 8px;
}

@include media-breakpoint-up(lg) {

   .wysiwyg-content p,
   #wysiwyg-editor p,
   #tinymce p {
     font-size: 1.7rem;
     line-height: 1.5;
   }

  .wysiwyg-content ul li,
  #wysiwyg-editor ul li,
  #tinymce ul li {
    font-size: 1.7rem;
    line-height: 1.5;
  }

  .wysiwyg-content ol li,
  #wysiwyg-editor ol li,
  #tinymce ol li {
    font-size: 1.7rem;
    line-height: 1.5;
  }
}
