.shortinfo {
  background: $secondary;
  padding: 1.5rem 0;
  color: #FFF;
}

.shortinfo__table {
  margin: 0 auto;
}

.shortinfo__table-img img {
    max-width: 4rem;
}

.shortinfo__table-content {
  padding-left: 1rem;
}

.shortinfo__headline {
  font-size: 1.4rem;
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
}

.shortinfo__text {
  font-size: 1.4rem;
  line-height: 1.1;
}

@include media-breakpoint-up(sm) {

  .shortinfo__table-img img {
    max-width: 5rem;
  }

  .shortinfo__table-content {
    padding-left: 2rem;
  }

  .shortinfo__headline {
    font-size: 2rem;
  }

  .shortinfo__text {
    font-size: 2rem;
  }

}

@include media-breakpoint-up(md) {

  .shortinfo__table-img img {
    max-width: 7rem;
  }

  .shortinfo__headline {
    font-size: 2.5rem;
  }

  .shortinfo__text {
    font-size: 2.5rem;
  }

}

@include media-breakpoint-up(lg) {

  .shortinfo__table-img img {
    max-width: 8.2rem;
  }

  .shortinfo__table-content {
    padding-left: 3.2rem;
  }

  .shortinfo__headline {
    font-size: 3rem;
  }

  .shortinfo__text {
    font-size: 3rem;
  }

}