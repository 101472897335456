.article-info {
  background-color: $secondary;
  min-height: 20px;
  padding: 15px 0 10px;
  font-family: 'Kanit', sans-serif;
  color: $text-light;
  margin-bottom: 40px;
}

.article-info__bg-streifen {
  display: none;
}

.details-collapse + .contact_2cols #request {
  margin-bottom: 30px;
}

.img-slick {
  display: none;
}

.btn-back {
  display: inline-block;
  border: solid 2px $tertiary;
  color: $text-light;
  padding: 5px 20px;
  margin-bottom: 16px;
  font-size: 14px;
}

.btn-back:before {
  content: '<';
  margin-right: 10px;
  padding-right: 10px;
}

.slider-main {
  height: 405px;
  margin-bottom: 15px;
}

.article-info .slick-prev {
  left: 0;
  z-index: 100;
}

.article-info .slick-next {
  right: 0;
  z-index: 100;
}

.slick-next, .slick-prev {
  background: rgba(0,0,0,0.0);
}

.slider-main .slick-prev {
  width: 25px;
  height: 50px;
  z-index: 100;
  color: $primary;
}

.slider-main .slick-next {
  width: 25px;
  height: 50px;
  z-index: 100;
  color: $primary;
}

.slider-main .slider-main-image-outer {
  background-color: #fff;
}

.slider-main .slider-main-image {
  max-height: 405px;
  max-width: 100%;
  margin: 0 auto;
  display: block!important;
  width: auto!important;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slider-nav {
  height: 120px;
  overflow: hidden;
}

.slider-nav .slider-nav-image-outer {
  background-color: #fff;
  max-height: 120px;
}

.slider-nav .slider-nav-image {
  max-width: 100%;
  margin: 0 auto;
  display: block!important;
  width: auto!important;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slider-nav .slick-prev {
  width: 25px;
  height: 100%;
  z-index: 100;
  color: $primary;
}

.slider-nav .slick-next {
  width: 25px;
  height: 100%;
  z-index: 100;
  color: $primary;
  transform: translate(2px,-50%);
}

.article-info .slick-prev:focus, .article-info .slick-next:focus {
   background: rgba(0,0,0,0.0);
}

.article-info .slick-prev:hover, .article-info .slick-next:hover {
  background: rgba(0,0,0,0.0);
}

.slick-next:before, .slick-prev:before {
  color: transparent;
}

.slider-angle--left, .slider-angle--right {
  width: 18px;
  height: 30px;
  position: absolute;
  z-index: 80;
  top: 50%;
}

.slider-main ~ .slider-angle--left, .slider-main ~ .slider-angle--right {
  transform: translateY(-22px);
}

.slider-nav ~ .slider-angle--left, .slider-nav ~ .slider-angle--right {
  transform: translateY(-50%);
}

.slider-angle--left {
  left: 16px;
}

.slider-angle--right {
  right: 16px;
}

.slider-nav .slick-slide {
  margin: 0 8px;
}

.slider-nav .slick-list {
  margin: 0 -8px;
}

.article-info__headline {
  font-size: 24px;
  margin-top: 8px;
}

.article-info .fahrzeugsuche__id {
  color: #fff;
}

.btn--article-info {
  display: block;
  background-color: transparent;
  border: solid 1px $text-light;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  line-height: normal;
  margin: 0 5px 8px 0;
  transform: skew(-10deg);
}

.btn--article-info span {
  transform: skew(10deg);
}

.article-info__price {
  position: relative!important;
  bottom: 0!important;
}

.article-info__mwst {
  position: relative!important;
  bottom: 10px!important;
}

.article-info__btngroup {
  margin-top: 10px;
}

.article-info__btngroup a {
  color: $text-light;
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .article-info {
    padding: 15px 0 20px;
  }

  .slider-nav .slick-slide {
    margin: 0 14px;
  }

  .slider-nav .slick-list {
    margin: 0 -14px;
  }

  .slider-main ~ .angle-bg-left, .slider-main ~ .angle-bg-right {
    position: absolute;
    width: 25px;
    height: 50px;
    z-index: 40;
    bottom: 50%;
    transform: translateY(36%);
    background-color: rgba(0,0,0,0.3);
  }

  .slider-main ~ .angle-bg-right {
    right: 15px;
  }

  .slider-nav__angle {
    position: absolute;
    width: 25px;
    height: 100%;
    z-index: 40;
    bottom: 50%;
    transform: translateY(50%);
    background-color: rgba(0,0,0,0.3);
  }

  .slider-nav__angle--bg-right {
    right: 15px;
  }

  .article-info__headline {
    font-size: 32px;
  }

  .btn--article-info {
    display: inline-block;
  }

  .article-info__btngroup {
    position: relative;
    bottom: -5px;
  }

  .btn--probefahrt {
    width: 230px;
  }

  .btn--drucken {
    width: 140px;
  }

  .btn--anfrage {
    width: 140px;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .article-info {
    padding: 15px 0 50px;
  }

  .article-info__bg {
    position: relative;
  }

  .slider-nav__angle--bg-right {
    transform: translateY(60px);
  }

  .article-info__bg-streifen {
    position: absolute;
    display: block;
    bottom: -50px;
  }

  .article-info__headline {
    font-size: 24px;
    margin-top: 0;
  }

  .article-info__price {
    bottom: 0;
  }

  .article-info__mwst {
    bottom: 10px;
  }

  .btn--article-info:hover {
    color: $text-light;
    background: $primary;
    border-color: $primary;
  }

  .article-info__btngroup {
    position: absolute;
    bottom: -5px;
  }

  .btn--probefahrt {
    width: 195px;
  }

  .btn--drucken {
    width: 120px;
  }

  .btn--anfrage {
    width: 120px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  .btn--probefahrt {
    width: 230px;
  }

  .btn--drucken {
    width: 140px;
  }

  .btn--anfrage {
    width: 140px;
  }
}