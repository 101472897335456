.teaser__3cols {
  margin: 6rem 0;
}

@include media-breakpoint-down(sm) {

  .teaser-boxes__item {
    max-width: 350px;
    margin: 0 auto 20px auto;
  }

}