.fullimage-overlay {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.fullimage-overlay__overlay {
  width: 100%;
  background: rgba($primary,0.8);
  padding: 5rem 3rem 8rem 4rem;
}

.fullimage-overlay__headline {
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.fullimage-overlay__text {
  color: #FFF;
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 400;
}


.fullimage-overlay__overlay .btn {
  font-size: 1.5rem;
  line-height: 3rem;
  letter-spacing: 0;
  padding: 0 1.5em;
}

@include media-breakpoint-up(sm) {

  .fullimage-overlay__overlay {
    max-width: 32rem;
  }

}

@include media-breakpoint-up(md) {

  .fullimage-overlay__overlay {
    max-width: 36rem;
  }

  .fullimage-overlay__headline {
    font-size: 3rem;
  }

  .fullimage-overlay__text * {
    font-size: 1.9rem;
  }

  .fullimage-overlay__overlay .btn {
    font-size: 1.9rem;
    line-height: 3.8rem;
  }

}

@include media-breakpoint-up(lg) {

  .fullimage-overlay__overlay {
    width: 100%;
    background: rgba($primary,0.8);
    padding: 3.5rem 3rem 3rem 4rem;
  }

  .fullimage-overlay__headline {
    font-size: 4rem;
  }

  .fullimage-overlay {
    min-height: 57rem;
  }

  .fullimage-overlay__overlay {
    max-width: 43.2rem;
    min-height: 57rem;
  }

  .fullimage-overlay__text {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  .fullimage-overlay__text p {
    margin-bottom: 1.5rem;
  }

  .fullimage-overlay__text ul li {
    margin-bottom: 0.5rem;
  }

  .fullimage-overlay__text * {
    font-size: 2.5rem;
  }

}