.topoffers {
  background-color: $secondary;
  background-image: url("../img/balken-bg.svg");
  background-position: -5rem bottom;
  background-repeat: no-repeat;
  padding: 3rem 0 3.8rem 0;
}

.topoffers .slick-track {
  display: inherit;
  align-items: unset;
  justify-content: unset;
}

.topoffers__globalheadline {
  width: 100%;
  display: block;
  color: $text-light;
  margin: 0 0 3rem 0;
  letter-spacing: 0.2rem;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  line-height: 1.2;
}

.topoffers__item {
  margin-bottom: 4rem;
}

.topoffers__item-img {
  margin-bottom: 2rem;
}

.topoffers__item-img img {
  width: 100%;
}

.topoffers__item-headline {
  font-size: 2rem;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
}

.topoffers__item-subline {
  font-size: 1.5rem;
  color: #FFF;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.topoffers__item-text {
  margin-bottom: 2rem;
  p {
    font-size: 1rem;
    color: #FFF;
    margin-bottom: 0;
  }
  ul {
    columns: 2;
    padding: 0 0 0 1.5rem;
    li {
      font-size: 1rem;
      color: #FFF;
    }
  }
}

.topoffers__item-price {
  color: $primary;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 0.2rem;
  width: 150px;
  white-space: nowrap;
}

.topoffers__item-mwst {
  display: block;
  color: $text-light;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.topoffers__item-btn {
  float: right;
  letter-spacing: 0;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  margin-right: 0.5rem;

  &:hover {
    background: $primary;
  }
}

.topoffers .slick-list {
  width: 100%;
}

.topoffers .slick-dotted .topoffers__item {
    margin-bottom: 4rem;
}

.topoffers .slick-dots {
  bottom: 0.5rem;
}

.topoffers .slick-next, .topoffers .slick-prev {
  width: 28px;
  height: 44px;
  z-index: 100;
  top: 43%;
}

.topoffers .slick-prev {
  background-image: url("../img/arrow_left.svg");
  background-repeat: no-repeat;
}

.topoffers .slick-next {
  background-image: url("../img/arrow_right.svg");
  background-repeat: no-repeat;
}

.topoffers__angle--left {
  width: 35px;
  height: 40px;
  position: absolute;
  z-index: 80;
  top: 180px;
  left: -45px;
}

.topoffers__angle--right {
  width: 35px;
  height: 40px;
  position: absolute;
  z-index: 80;
  top: 180px;
  right: -40px;
}

@media (max-width: 365px) {
  .topoffers__item-btn {
    padding: 0 1.2rem!important;
    margin-right: 0;
  }
}

@include media-breakpoint-up(md) {
  .topoffers__item-headline {
    font-size: 2.5rem;
  }

  .topoffers__item-subline {
    font-size: 1.7rem;
  }

  .topoffers__item-text {
    p {
      font-size: 1.2rem;
    }
    ul {
      li {
        font-size: 1.2rem;
      }
    }
  }

  .topoffers__item-price {
    font-size: 2.5rem;
  }

  .topoffers__item-mwst {
    font-size: 1.2rem;
  }

  .topoffers__item-btn {
    font-size: 1.7rem;
  }
}

@include media-breakpoint-up(lg) {
  .topoffers__item-subline {
    font-size: 1.5rem;
  }
  .topoffers__item-price {
    font-size: 2rem;
  }

  .topoffers__item-mwst {
    font-size: 1rem;
  }

  .topoffers__item-btn {
    font-size: 1.5rem;
    padding: 0 1.5rem!important;
    margin-right: 0;
  }
  .topoffers__item-text {
    p {
      font-size: 1rem;
    }
    ul {
      li {
        font-size: 1rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .topoffers__item-subline {
    font-size: 1.7rem;
  }
  .topoffers__item-price {
    font-size: 2.5rem;
  }

  .topoffers__item-mwst {
    font-size: 1.2rem;
  }

  .topoffers__item-btn {
    font-size: 1.7rem;
    padding: 0 2.5rem!important;
  }
  .topoffers__item-text {
    p {
      font-size: 1.2rem;
    }
    ul {
      li {
        font-size: 1.2rem;
      }
    }
  }
}