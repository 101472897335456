.contact_2cols-name {
  color: $primary;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
}

.contact_2cols-position {
  color: $primary;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
}

.contact_2cols-phone {
  font-size: 1.3rem;
  line-height: 1.4;
  letter-spacing: 0;

  &:before {
    content: "";
    background-image: url("../img/icon_phone.svg");
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
    position: relative;
    top: 0.3rem;
  }

}

.contact_2cols-mail {
  a {
    font-size: 1.3rem;
    line-height: 1.4;
    letter-spacing: 0;
    color: $text-dark;
  }

  &:before {
    content: "";
    background-image: url("../img/icon_mail.svg");
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
    position: relative;
    top: 0.3rem;
  }
}

@include media-breakpoint-up(md) {
  .contact_2cols {
    padding-top: 15px;
  }
}

@include media-breakpoint-up(lg) {

  .contact_2cols-name {
    font-size: 1.7rem;
  }

  .contact_2cols-position {
    font-size: 1.7rem;
  }

  .contact_2cols-phone {
    font-size: 1.5rem;
  }

  .contact_2cols-mail {
    a {
      font-size: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {

  .contact_2cols-name {
    font-size: 2rem;
    margin-bottom: 0.6rem;
    letter-spacing: 0.08rem;
  }

  .contact_2cols-position {
    font-size: 2rem;
    margin-bottom: 1rem;
    letter-spacing: 0.08rem;
  }

  .contact_2cols-phone {
    font-size: 2rem;

    &:before {
      height: 2rem;
      width: 2rem;
      top: 0.3rem;
      margin-right: 1rem;
    }
  }

  .contact_2cols-mail {

    a {
      font-size: 2rem;
    }

    &:before {
      height: 2rem;
      width: 2rem;
      top: 0.3rem;
      margin-right: 1rem;
    }
  }
}