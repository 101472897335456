.main-teaser__item {
  position: relative;
  background: $primary;
  height: 100%;

  img {
    margin: 0 auto;
  }
}

.main-teaser__item-overlay {
  background: $primary;
  padding: 1rem 0 1rem 0;

  h2 {
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  p {
    color: #FFF;
  }
}

.slick-dotted .main-teaser__item-overlay {
  padding: 1rem 0 3rem 0;
}

.main-teaser .slick-dots {
  bottom: 2.5rem;
}

.slick-track {
  display: flex;
  align-items: stretch;
  justify-content: center;
  .slick-slide{
    height: auto;
  }
}

@include media-breakpoint-down(md) {
  .main-teaser .container {
    width: 100%;
    max-width: none;
  }
}


@include media-breakpoint-up(md) {

  .main-teaser .container {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .main-teaser__item-overlay {
    position: absolute;
    background: none;
    padding: 0!important;
    width: 100%;
    margin-top: 2rem;

    h2 {
      display: inline-block;
      background: $primary;
      padding: 0.5rem 1.5rem;
      transform: skew(-10deg);
      font-size: 2.4rem;
      //opacity: 0.1;
      //visibility: hidden;
      //transition: all 0.7s 0.5s;
      font-style: normal;
      position: relative;
    }

    p {
      color: $text-dark;
      max-width: 50%;
      //text-shadow: 0 0 0.6rem #fff;
      letter-spacing: 0;
      //opacity: 0;
      //transition: all 0.7s 0.7s;
      position: relative;
      padding-left: 1.5rem;
    }

  }

}

@include media-breakpoint-up(lg) {

  .main-teaser__item-overlay {
    margin-top: 3rem;

    h2 {
      padding: 0.8rem 2rem;
      font-size: 3rem;
    }

    p {
      font-size: 2rem;
    }

  }

}

@include media-breakpoint-up(xl) {

  .main-teaser__item-overlay {
    margin-top: 4rem;

    h2 {
      font-size: 4rem;
      padding: 0 2rem;
      line-height: 6rem;
    }

    p {
      font-size: 2.5rem;
    }

  }

  .main-teaser .slick-dots {
    bottom: 2.5rem;
  }

}