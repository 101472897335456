.details-collapse *{
  font-family: 'Kanit', sans-serif;
}

.details-collapse {
  margin-bottom: 60px;
}

.details-collapse__item {
  position: relative;
  background-color: #FFF;
  color: $secondary;
  cursor: pointer;
  padding: 12px 0;
  width: 100%;
  border: none;
  border-top: solid 2px $primary;
  text-align: left;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Kanit', sans-serif;
}

.details-collapse__text {
  font-size: 14px;
  margin-bottom: 0;
}

.details-collapse__text b {
  font-size: 16px;
}

.details-collapse__group {
  border-bottom: solid 2px $primary;
}

.details-collapse__item:focus {
  outline: none;
}

.active /*, .details-collapse__item:hover*/ {
  border-bottom: solid 2px $primary;
}

.datails-collapse__angle {
  position: absolute;
  width: 20px;
  height: 30px;
  right: 15px;
  bottom: 8px;
}

.details-collapse .active .datails-collapse__angle, .details-collapse__item[aria-expanded=true] .datails-collapse__angle {
  transform: rotate(180deg);
}

.details-collapse__list {
  padding: 20px 0;
}

.details-collapse__list-group {
  display: inline-flex;
  width: 100%;
}

.details-collapse__list-item {
  display: inline-block;
  list-style-type: none;
  font-family: 'Kanit', sans-serif;
  font-size: 14px;
  width: 50%;
}

.details-collapse sup {
  top: 0.5em;
}

.details-collapse__list-value {
  display: inline-flex;
  width: 50%;
  padding-left: 15px;
  font-size: 14px;
}

.details-collapse__item-headline,
.details-collapse__item-subline {
  word-break: break-word;
}

.details-collapse__item-subline {
  font-weight: 400;
  text-transform: none;
  font-size: smaller;
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  .details-collapse__item {
    font-size: 24px;
  }

  .datails-collapse__angle {
    width: 30px;
    height: 40px;
  }
}
