.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.item-wrapper {
  height: 100%;
  position: relative;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-bottom {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.no-margin {
  margin-bottom: 0!important;
  margin-top: 0!important;
}

.no-padding {
  padding-bottom: 0!important;
  padding-top: 0!important;
}

.va-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}