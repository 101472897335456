.wysiwyg-content p[align="center"] {
  max-width: 82rem;
  margin: 0 auto;
}


@include media-breakpoint-up(md) {

  .text-cols2 {
    columns: 2;
    page-break-inside: avoid;
    break-inside: avoid;
    column-gap: 30px;

    p {
      margin: 0;
    }
  }
}


