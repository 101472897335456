.img60p-text40p {
  margin: 6rem 0;
}

.img60p-text40p .h1 {
  text-align: left;
  margin-top: 0;
}

@media (max-width: 991px) {

  .headline-container + .img60p-text40p {
    margin: 1rem 0 6rem 0;
  }
}

@media (max-width: 767px) {

  .img60p-text40p .h1 {
    margin-bottom: 0;
  }
}

