html {
  font-size: 10px;
  max-width: 192rem;
  margin: 0 auto;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: Kanit, Arial, sans-serif;
  letter-spacing: 0.08rem;
}

p {
  font-size: 1.5rem;
  line-height: 1.4;
  hyphens: auto;
  letter-spacing: 0;
}

a {
  transition: all 0.3s;
}

ul {
  padding: 0 0 0 2rem;
  margin: 0;
  font-size: 1.5rem;
  letter-spacing: 0;
}

.custom-row {
  margin-left: -15px;
  margin-right: -15px;
}

pre {
  font-size: 1.2rem;
}

// HALF GUTTER WIDTH ON XS
@include media-breakpoint-down(xs) {
  .row {
    margin-right: -$grid-gutter-width / 4;
    margin-left: -$grid-gutter-width / 4;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: $grid-gutter-width / 4;
    padding-left: $grid-gutter-width / 4;
  }
}


@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

  .container {
    max-width: 76rem;
  }

}

@include media-breakpoint-up(lg) {

  .container {
    max-width: 96rem;
  }

  p {
    font-size: 1.7rem;
    line-height: 1.5;
  }

  ul {
    padding: 0 0 0 2rem;
    font-size: 1.7rem;
  }

}

@include media-breakpoint-up(xl) {

  .container {
    max-width: 114rem;
  }

}