.stickyicons {
  display: none;
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 9998;
}

.stickyicons__listitem {
  position: relative;
  display: block;
  background: $primary;
  margin: 0 0 0.5rem;
  line-height: 4.4rem;
  padding: 0.7rem;
  list-style: none;
  right: -100%;
  margin-left: -5.6rem;
  transition: all 0.3s;

  &:hover {
    right: 0;
    margin-left: 0;
  }
}

.stickyicons__listitem-link {
  position: relative;
  display: block;
  color: #FFF;

  &:hover {
    color: #FFF;
  }
}

.stickyicons__listitem-img {
  display: inline-block;
  padding: 0 0.6rem 0 0.5rem;
  width: 4.4rem;
  height: 4.4rem;
}

.stickyicons__listitem-img img {
  height: 3.6rem;
}

.stickyicons__listitem-text {
  line-height: 4.4rem;
  border-left: 0.1rem solid #FFF;
  padding-left: 1rem;
}

@include media-breakpoint-up(xl) {
  .stickyicons {
    display: block!important;
  }
}