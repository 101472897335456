.iconteaser {
  margin: 6rem 0 2rem 0;
  text-align: center;
}

.iconteaser__item {
  display: block;
  margin-bottom: 4rem;
}

.iconteaser__item-image {
  transition: all 0.5s;
}

.iconteaser__item-headline {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;
}

.iconteaser__item-image {
  margin-bottom: 2rem;
}

.iconteaser__item:hover .iconteaser__item-image,
.iconteaser__item:active .iconteaser__item-image,
.iconteaser__item:focus .iconteaser__item-image {
  transform: rotate3d(0, 1, 0, 180deg);
}

@include media-breakpoint-up(lg) {

  .iconteaser {
    margin: 6rem 0;
  }

  .iconteaser__item {
    margin-bottom: 0;
  }

}