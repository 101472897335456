.btn {
  display: inline-block;
  line-height: 4rem;
  background: $primary;
  font-size: 1.7rem;
  padding: 0 2.5rem;
  transform: skew(-10deg);
  color: $text-light;
  border: none;

  span {
    display: inline-block;
    transform: skew(10deg);
  }
}

.btn-arrow:after {
  content: " >";
  position: relative;
  display: inline-block;
  transform: skew(10deg);
  padding-left: 1rem;
}

.btn.btn-dark {
  background: $secondary;
}

@include media-breakpoint-up(lg) {
  .btn:hover {
    color: #FFF;
    background: $secondary;
  }
}