.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  bottom: 0;
}

.slick-dots li button {
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  opacity: 0.7;
  border-radius: 100%;
  transition: all 0.3s;
  transform: translateY(15px);
}

.slick-dots li.slick-active button {
  opacity: 1;
}

.slick-dots li button:before {
  content: none;
}

@include media-breakpoint-up(xl) {

  .slick-dots li {
    margin: 0 1rem;
  }

  .slick-dots li button {
    //width: 2rem;
    //height: 2rem;
  }

}