.header-global {
  height: 2.4rem;
  background: $secondary;
}

.header-global__24hours img {
  position: relative;
  height: 2.4rem;
  left: -1rem;
}

.header-inner {
  position: relative;
}

.logo {
  display: block;
  margin-top: 1rem;

  img {
    height: 60px;
  }
}

.global-nav {
  position: relative;
}

.global-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.global-nav__listitem {
  display: inline-block;
  float: left;
  padding-left: 5px;
  line-height: 16px;
}

.global-nav__listitem:not(:last-child):after {
  content: "//";
  padding-left: 5px;
  color: #FFF;
  font-size: 0.9rem;
  font-weight: 400;
}

.global-nav__listitem > a {
  color: $text-light;
  font-size: 0.9rem;
  font-weight: 400;
  &:hover {
    color: $primary;
  }
}

.header-main {
  position: relative;
  padding-bottom: 1rem;
}

.header-brands {
  position: absolute;
  right: 0;
  height: 2.5rem;
  padding-top: 1rem
}

.header-brands__item:not(:last-of-type) {
  margin-right: 1rem;
}

.header-brands__item {
  display: block;
}

.header-brands__item img {
  height: 2rem;
  filter: grayscale(100%);
  transition: all 0.3s;
}

.header-brands__item:hover img,
.header-brands__item:focus img,
.header-brands__item:active img {
  filter: grayscale(0%);
}


@media (max-width: 340px) {

  .global-nav__listitem > a {
    letter-spacing: 0;
  }

  .logo img {
    max-width: 130%;
  }

  .menu-wrapper {
    height: 1.4rem;
  }

  .navbar-toggle {
    height: 4.4rem;
    top: -2rem;
  }

  #main-nav {
    top: 9.4rem;
  }

  .navbar-toggle[aria-expanded=true] .icon-bar--one {
    transform: rotate(45deg) translate(7px,3px);
  }

  .navbar-toggle[aria-expanded=true] .icon-bar--three {
    transform: rotate(-45deg) translate(7px,-3px);
  }
}

@media (max-width: 374px) {

  .navbar-toggle {
    height: 4.4rem;
    top: -2rem;
  }
}

@media (min-width: 375px) {

  .logo {
    img {
      height: 3.4rem;
      margin: 0.5rem 0;
    }
  }

  .header-brands {
    right: 7.5rem;
    padding-top: 2.2rem;
    height: 7.8rem;
  }

  .header-brands__item img {
    height: 2.5rem;
  }

  .navbar-toggle {
    top: -3.4rem;
  }

  .menu-wrapper {
    height: 0;
  }

  @media (max-width: 767px) {

    #main-nav ul.level1 {
      top: -45px;
    }
  }

}

@media (min-width: 425px) {

  .logo {
    img {
      height: 4.4rem;
      margin: 0;
    }
  }

}

@include media-breakpoint-up(sm) {

  .header-global {
    height: 3.6rem;
  }

  .header-global__24hours img {
    height: 3.6rem;
  }

  .global-nav__listitem > a {
    font-size: 1.4rem;
  }

  .global-nav__listitem:not(:last-child):after {
    font-size: 1.4rem;
  }

  .header-brands {
    right: auto;
    left: 50%;
  }

}

@include media-breakpoint-up(md) {

  .header-main {
    position: relative;
    height: 14.2rem; //height: 11.2rem;
    padding: 0.5rem;
    //padding-top: 2.2rem;
    //top: -7.8rem;
  }

  .logo {
    margin: 1rem 0 1rem 0; //margin: 0 0 4.1rem 0;

    img {
      height: auto;
      width: 29rem;
    }
  }

  .header-brands {
    position: relative;
    left: auto;
    float: right;
  }

  .menu-wrapper {
    height: 2.2rem;
  }

  .header-brands__item img {
    height: 4.4rem;
  }

  .header-brands__item:not(:last-of-type) {
    margin-right: 2rem;
  }

}


@include media-breakpoint-up(lg) {

  .logo {
    margin: 0;
    transform: translate(0, 32px);

    img {
      height: auto;
      width: 26rem;
    }
  }

  .header-global {
    height: 4.9rem;
  }

  .header-global__24hours img {
    height: 4.9rem;
  }

  .global-nav__listitem > a {
    font-size: 1.6rem;
  }

  .global-nav__listitem:not(:last-child):after {
    font-size: 1.6rem;
  }

  .header-main {
    top: auto;
    padding: 0.9rem 0 0 0; //padding: 1.8rem 0 0 0;
  }

}

@include media-breakpoint-up(xl) {

  .logo {
    margin-top: 1rem;
    transform: translate(0,24px);
    img {
      height: auto;
      width: 29rem;
    }
  }

  .header-main {
    padding: 0;
  }

}