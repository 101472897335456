.btn {
  display: inline-block;
  line-height: 4rem;
  background: $primary;
  font-size: 1.7rem;
  padding: 0 2.5rem;
  transform: skew(-10deg);
  color: $text-light;

  span {
    display: inline-block;
    transform: skew(10deg);
  }
}

.btn-arrow:after {
  content: " >";
  position: relative;
  display: inline-block;
  transform: skew(10deg);
  padding-left: 1rem;
}

.btn:hover {
  color: $text-light;
  //background: $secondary;
}

.btn--offer {
  position: absolute;
  width: 165px;
  height: 40px;
  line-height: 2.5rem;
  font-size: 16px;
  padding: 0 1rem;
  right: 15px;
  bottom: -70px;
}

.btn--offer a {
  color: $text-light;
}

.btn--offer span {
  position: absolute;
  left: 20px;
  top: 6px;
  margin: auto;
}

.fahrzeugsuche {
  color: $secondary;
  font-family: 'Kanit', sans-serif;
}

.fahrzeugsuche .show-more {
  display: block;
  margin: $grid-gutter-width auto;
}

.fahrzeugsuche__item {
  padding: 40px 0 90px;
  border-bottom: solid 3px $primary;
}

.fahrzeugsuche__img {
  width: 100%;
  margin-top: 7px;
}

.fahrzeugsuche__img img {
  width: 100%;
}

.fahrzeugsuche__headline {
  font-size: 24px;
  font-weight: 700;
}

.fahrzeugsuche__subline {
  text-align: left;
  float: left;
  font-size: 14px;
}

.fahrzeugsuche__id {
  color: $primary;
  font-weight: 700;
  font-size: 14px;
  text-align: right;
}

.fahrzeugsuche__list {
  margin: 1em 0;
}

.fahrzeugsuche__list ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.fahrzeugsuche__info {
  line-height: 24px;
  margin-bottom: 60px;
}

.fahrzeugsuche__price {
  position: absolute;
  font-size: 40px;
  font-weight: 700;
  color: $primary;
  bottom: -35px;
}

.fahrzeugsuche__mwst {
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  bottom: -40px;
}

.sort-filter {
  background: transparent!important;
}

.sort-filter .selectric {
  background: transparent!important;
}

.sort-filter .selectric .label {
  color: #06b4c9!important;
}

@media screen and (min-width: 425px) {
  .btn--offer {
    bottom: -50px;
  }

  .fahrzeugsuche__item {
    padding: 40px 0 70px;
  }

  .fahrzeugsuche__price {
    bottom: -45px;
  }

  .fahrzeugsuche__mwst {
    bottom: -50px;
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  .btn--offer {
    bottom: 0;
  }

  .fahrzeugsuche__item {
    padding: 40px 0;
  }

  .fahrzeugsuche__price {
    bottom: 3px;
  }

  .fahrzeugsuche__mwst {
    bottom: -4px;
  }

  .fahrzeugsuche__img {
    min-height: 350px;
  }
}

@include media-breakpoint-up(xl) {
  .fahrzeugsuche__img {
    min-height: 300px;
  }
}


