/* kanit-regular - latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kanit-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Kanit Regular'), local('Kanit-Regular'),
  url('../fonts/kanit-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kanit-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kanit-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kanit-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kanit-v4-latin-regular.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-700 - latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/kanit-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Kanit Bold'), local('Kanit-Bold'),
  url('../fonts/kanit-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kanit-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kanit-v4-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kanit-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kanit-v4-latin-700.svg#Kanit') format('svg'); /* Legacy iOS */
}
/* kanit-700italic - latin */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/kanit-v4-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Kanit Bold Italic'), local('Kanit-BoldItalic'),
  url('../fonts/kanit-v4-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kanit-v4-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kanit-v4-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kanit-v4-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kanit-v4-latin-700italic.svg#Kanit') format('svg'); /* Legacy iOS */
}