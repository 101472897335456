.img25p-text75p {
  margin: 6rem 0;
}

.img25p-text75p img {
  display: block;
  margin-bottom: 2rem;
}

.img25p-text75p .h1,
.img25p-text75p .h2 {
  text-align: left;
  margin-top: 0;
}

.img25p-text75p p {
  margin-bottom: 2rem;
}

@include media-breakpoint-up(lg) {



}

@media (max-width: 767px) {

  .img25p-text75p .h1,
  .img25p-text75p .h2 {
    margin-bottom: 0;
  }
}