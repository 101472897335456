h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
  font-weight: normal;
}

h1, .h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: $text-dark;
  font-style: italic;
  margin-top: 5rem;
}

h2, .h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: $text-dark;
}

h3, .h3 {
  font-size: 1.8rem;
  line-height: 2rem;
  color: $text-dark;
  text-transform: uppercase;
  font-weight: 700;
}


@include media-breakpoint-up(md) {

  h1, .h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  h3, .h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

}


@include media-breakpoint-up(lg) {

  h1,.h1 {
    font-size: 4rem;
    line-height: 5rem;
  }

  h2, .h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  h3, .h3 {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
  }

}