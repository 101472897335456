.sitemap__main {
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sitemap__navitem {
  display: block;
  margin-bottom: 5px;
  &:hover {
    text-decoration: underline;
  }
}

@for $i from 1 through 5 {
  .sitemap__navitem--level#{$i} {
    margin-left: $i * 10 + px;
  }
}
